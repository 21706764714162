import {
  SET_DATA_SOAL,
  RESET_DATA_SOAL,
  SET_CURRENT_SOAL,
  SET_JAWABAN_SOAL_PG,
  SET_JAWABAN_SOAL_PG_COMPLEX,
  SET_STATUS_JAWAB_SOAL,
  SET_HASIL_JAWAB_SOAL,
  SET_JAWABAN_SOAL_ISIAN_SINGKAT,
  SET_JAWABAN_SOAL_BENAR_SALAH,
  SET_JAWABAN_SURVEY_LINGKUNGAN,
  ADD_CURRENT_ID_SOAL,
  SET_HISTORY_SOAL,
  SET_HISTORY_STATUS,
  SET_JAWABAN_SOAL_MENJODOHKAN,
  SET_DATA_STATUS,
  SET_STAGE,
} from "../actions";

const defaultStatusAKM = [
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
  { status: "belum_dijawab", hasil: false },
];

const base = {
  soal: [],
  id_soal: [],
  current: {
    index: -1,
  },
  // belum_dijawab | dijawab | ragu_ragu
  status: defaultStatusAKM,
  stage: 0
};

const initialState = base;

const dataSoal = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DATA_SOAL:
      return { ...state, soal: [...state.soal, payload] };
    case SET_STAGE:
      return { ...state, stage: payload };
    case SET_HISTORY_SOAL:
      return { ...state, soal: payload };
    case SET_HISTORY_STATUS:
      return { ...state, status: payload };
    case SET_CURRENT_SOAL:
      return { ...state, current: payload };
    case ADD_CURRENT_ID_SOAL:
      return {
        ...state,
        id_soal: [...state.id_soal, payload],
      };
    case SET_JAWABAN_SOAL_PG_COMPLEX:
      return {
        ...state,
        soal: state.soal.map((item, index) => {
          if (state.current.index === index) {
            return {
              ...item,
              exam_question_answer: {
                ...item.exam_question_answer,
                jawaban: item.exam_question_answer.jawaban.map((el, i) => {
                  if (i === payload) {
                    return {
                      ...el,
                      status_jawaban: el.status_jawaban === "1" ? "0" : "1",
                    };
                  }
                  return el;
                }),
              },
            };
          }
          return item;
        }),
      };
    case SET_JAWABAN_SURVEY_LINGKUNGAN:
      return {
        ...state,
        soal: state.soal.map((item, index) => {
          if (state.current.index === index) {
            return {
              ...item,
              exam_question_answer: {
                ...item.exam_question_answer,
                jawaban: item.exam_question_answer.jawaban.map((el, i) => {
                  if (i === payload.index) {
                    return {
                      ...el,
                      status_jawaban: String(payload.status_jawaban),
                    };
                  }
                  return el;
                }),
              },
            };
          }
          return item;
        }),
      };
    case SET_JAWABAN_SOAL_BENAR_SALAH:
      return {
        ...state,
        soal: state.soal.map((item, index) => {
          if (state.current.index === index) {
            return {
              ...item,
              exam_question_answer: {
                ...item.exam_question_answer,
                jawaban: item.exam_question_answer.jawaban.map((el, i) => {
                  if (i === payload.index) {
                    return {
                      ...el,
                      status_jawaban: payload.status,
                    };
                  }
                  return el;
                }),
              },
            };
          }
          return item;
        }),
      };
    case SET_JAWABAN_SOAL_ISIAN_SINGKAT:
      return {
        ...state,
        soal: state.soal.map((item, index) => {
          if (state.current.index === index) {
            return {
              ...item,
              exam_question_answer: {
                ...item.exam_question_answer,
                jawaban: item.exam_question_answer.jawaban.map((el, i) => {
                  if (i === payload.index) {
                    return {
                      ...el,
                      text_jawaban: payload.text_jawaban,
                    };
                  }
                  return el;
                }),
              },
            };
          }
          return item;
        }),
      };
    case SET_JAWABAN_SOAL_PG:
      return {
        ...state,
        soal: state.soal.map((item, index) => {
          if (state.current.index === index) {
            return {
              ...item,
              exam_question_answer: {
                ...item.exam_question_answer,
                jawaban: item.exam_question_answer.jawaban.map((el, i) => {
                  if (i === payload) {
                    return {
                      ...el,
                      status_jawaban: "1",
                    };
                  } else {
                    return {
                      ...el,
                      status_jawaban: "0",
                    };
                  }
                }),
              },
            };
          }
          return item;
        }),
      };
    case SET_JAWABAN_SOAL_MENJODOHKAN:
      const pola_jawaban =
        state.soal[state.current.index].exam_question_answer.pola_jawaban;
      const exam_question_answer =
        state.soal[state.current.index].exam_question_answer;
      return {
        ...state,
        soal: state.soal.map((item, index) => {
          if (state.current.index === index) {
            return {
              ...item,
              exam_question_answer: {
                ...exam_question_answer,
                pola_jawaban: pola_jawaban.map((el, i) => {
                  if (i === 2) {
                    return { status_jawaban: payload };
                  }
                  return el;
                }),
              },
            };
          }
          return item;
        }),
      };

    // console.log(
    //   state.soal[state.current.index].exam_question_answer.pola_jawaban
    // );

    // var x = {
    //   ...state,
    //   soal: state.soal.map((item, index) => {
    //     if (state.current.index === index) {
    //       console.log("-------> state", item);
    //       return {
    //         ...item,
    //         exam_question_answer: {
    //           ...state.exam_question_answer,
    //           pola_jawaban: state.exam_question_answer.pola_jawaban,
    //           // .map(
    //           //   (item, index) => {
    //           //     if (index === 2) {
    //           //       return { status_jawaban: payload };
    //           //     }
    //           //     return item;
    //           //   }
    //           // ),
    //         },
    //       };
    //     }
    //     return item;
    //   }),
    // };
    // return state;
    case SET_DATA_STATUS:
      return {
        ...state,
        status: payload
      };
    case SET_STATUS_JAWAB_SOAL:
      return {
        ...state,
        status: state.status.map((item, index) => {
          if (index === state.current.index) {
            return { ...item, status: payload.status };
          }
          return item;
        }),
      };
    case SET_HASIL_JAWAB_SOAL:
      return {
        ...state,
        status: state.status.map((item, index) => {
          if (index === state.current.index) {
            return { ...item, hasil: Boolean(payload.hasil) };
          }
          return item;
        }),
      };
    case RESET_DATA_SOAL:
      return initialState;
    default:
      return state;
  }
};

export default dataSoal;
