import { combineReducers } from "redux";
import sidebarShowReducer from "./sidebarShowReducer";
import userReducer from "./userReducer";
import dataExamReducer from "./dataExamReducer";
import dataSoalReducer from "./dataSoalReducer";
import userSoalReducer from "./userSoalReducer";
const rootReducer = combineReducers({
  sidebarShow: sidebarShowReducer,
  user: userReducer,
  dataExam: dataExamReducer,
  dataSoal: dataSoalReducer,
  userSoal: userSoalReducer,
});

export default rootReducer;
