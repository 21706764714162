export const SET_DATA_SOAL = "soal.SET_DATA_SOAL";
export const SET_HISTORY_SOAL = "soal.SET_HISTORY_SOAL";
export const SET_HISTORY_STATUS = "soal.SET_HISTORY_STATUS";
export const SET_CURRENT_SOAL = "soal.SET_CURRENT_SOAL";
export const RESET_DATA_SOAL = "soal.RESET_DATA_SOAL";
export const SET_JAWABAN_SOAL_PG = "soal.SET_JAWABAN_SOAL_PG";
export const SET_JAWABAN_SOAL_PG_COMPLEX = "soal.SET_JAWABAN_SOAL_PG_COMPLEX";
export const SET_JAWABAN_SOAL_BENAR_SALAH = "soal.SET_JAWABAN_SOAL_BENAR_SALAH";
export const SET_JAWABAN_SOAL_ISIAN_SINGKAT =
  "soal.SET_JAWABAN_SOAL_ISIAN_SINGKAT";
export const SET_JAWABAN_SOAL_MENJODOHKAN = "SET_JAWABAN_SOAL_MENJODOHKAN";
export const SET_STATUS_JAWAB_SOAL = "soal.SET_STATUS_JAWAB_SOAL";
export const SET_HASIL_JAWAB_SOAL = "soal.SET_HASIL_JAWAB_SOAL";
export const SET_JAWABAN_SURVEY_LINGKUNGAN =
  "soal.SET_JAWABAN_SURVEY_LINGKUNGAN";
export const ADD_CURRENT_ID_SOAL = "soal.ADD_CURRENT_ID_SOAL";
export const SET_DATA_STATUS ="soal.SET_DATA_STATUS";
export const SET_STAGE ="soal.SET_STAGE";
