import { createStore, compose, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducers from "./reducers";
import ReduxThunk from "redux-thunk";
import logger from "redux-logger";
import localforge from "localforage";

const persistConfig = {
  key: "root",
  storage: localforge,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

let middleware = [ReduxThunk];
if (
  process.env.REACT_APP_ENVIRONTMENT === "local" ||
  process.env.REACT_APP_ENVIRONTMENT === "development"
) {
  middleware = [...middleware, logger];
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const appMiddleware = applyMiddleware(...middleware);
// const store = createStore(rootReducers, composeEnhancers(appMiddleware));

export const store = createStore(
  persistedReducer,
  composeEnhancers(appMiddleware)
);
export const persistor = persistStore(store);
// export default () => {
//   return { store, persistor };
// };
// export default store;
