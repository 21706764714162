import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./styles/style.css";

const loading = (
  <div
    style={{ height: "100vh" }}
    className="d-flex align-items-center justify-content-center"
  >
    <div className="spinner-grow text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    <div className="spinner-grow text-secondary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    <div className="spinner-grow text-success" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    <div className="spinner-grow text-danger" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    <div className="spinner-grow text-warning" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const DataConfirmation = React.lazy(() =>
  import("src/views/pages/DataConfirmation")
);
const ExamStart = React.lazy(() => import("src/views/pages/ExamStart"));
const Exam = React.lazy(() => import("src/views/pages/Exam"));
const ExamFinish = React.lazy(() => import("src/views/pages/ExamFinish"));
const ExamSimulasi = React.lazy(() => import("src/views/pages/ExamSimulasi"));
const ExamRegular = React.lazy(() => import("src/views/pages/ExamRegular"));

const App = () => {
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route
            exact
            path="/data-confirmation"
            name="Login Page"
            render={(props) => <DataConfirmation {...props} />}
          />
          <Route
            exact
            path="/exam-start"
            name="Start Exam"
            render={(props) => <ExamStart {...props} />}
          />
          <Route
            exact
            path="/exam-finish"
            name="Start Exam"
            render={(props) => <ExamFinish {...props} />}
          />
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/exam"
            name="Exam"
            render={(props) => <Exam {...props} />}
          />
          <Route
            exact
            path="/exam-simulasi"
            name="Exam Simulasi"
            render={(props) => <ExamSimulasi {...props} />}
          />
          <Route
            exact
            path="/exam-regular"
            name="Exam Regular"
            render={(props) => <ExamRegular {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
