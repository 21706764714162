import {
  SET_EXAM_TOKEN,
  SET_AVAILABLE_EXAM,
  SET_CURRENT_EXAM,
  RESET_DATA_EXAM,
  SET_CURRENT_USER_EXAM,
} from "../actions";

const initialState = {
  current: {
    token: "",
  },
  timer: 0,
  currentUser: {},
  available: [],
};

const dataExamReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_USER_EXAM:
      return {
        ...state,
        currentUser: payload,
      };
    case SET_EXAM_TOKEN:
      return { ...state, current: { ...state.current, token: payload } };
    case SET_AVAILABLE_EXAM:
      return { ...state, available: payload };
    case SET_CURRENT_EXAM:
      return { ...state, current: payload };
    case RESET_DATA_EXAM:
      return initialState;
    default:
      return state;
  }
};

export default dataExamReducer;
