import { SET_DATA_USER, RESET_USER, SET_TOKEN } from "../actions";

const initialState = {
  token: "",
  profile: {
    data: {
      user_participant: {
        nis: "",
        nik: "",
      },
    },
  },
};

const dataSiswaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TOKEN:
      return payload;
    case SET_DATA_USER:
      return payload;
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
};

export default dataSiswaReducer;
