import {
  SET_TOTAL_SOAL,
  SET_ID_USER_SOAL,
  RESET_DATA_SOAL,
  SET_WAKTU_MULAI_SERVER,
  SET_HISTORY_ID_USER_SOAL,
} from "../actions";

const initialState = {
  totalSoal: 0,
  id_soal: [],
  waktu_server: new Date(),
};

const userSoalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TOTAL_SOAL:
      return { ...state, totalSoal: payload };
    case SET_ID_USER_SOAL:
      return { ...state, id_soal: [...state.id_soal, payload] };
    case SET_HISTORY_ID_USER_SOAL:
      return { ...state, id_soal: payload };
    case SET_WAKTU_MULAI_SERVER:
      return { ...state, waktu_server: payload };
    case RESET_DATA_SOAL:
      return initialState;
    default:
      return state;
  }
};

export default userSoalReducer;
